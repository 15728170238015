body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  user-select: none;
}

*,*:before,*:after{
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.snackbar {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 1rem;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
 However, delay the fade out process for 2.5 seconds */
 -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
 animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.tabs {
  width: 100%;
  float: none;
  list-style: none;
  position: relative;
  text-align: left;
  padding: 0;
  z-index: 0;
}
.tabs__div {
    width: 100%;
    align-self: center;
    user-select: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    height: calc(100vh - 100px);
    margin: 0px;
}

.tabs > li {
  float: left;
  display: block;
}
.tabs input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
}
.tabs .tab-content {
  z-index: 2;
  display: none;
  overflow-y: scroll;
  height: calc(100vh - 200px);
  width: 100%;
  padding: 1rem;
  position: absolute;
  top: 53px;
  left: 0;
  background: #612e76;
}
.tabs [id^="tab"]:checked + label {
  top: 0;
  padding-top: 17px;
  background: #612e76;
}
.tabs [id^="tab"]:checked ~ [id^="tab-content"] {
  display: block;
}

p.link {
  clear: both;
}
p.link a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
  background-color: #612e76;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
p.link a:hover {
  background-color: #522764;
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outAnimation {
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

/***** * * Radio checked style related * the fun part starts here * */
input[type=radio] {
  display: none;
}
/** * How it should look when hovered */
.active-label, label:focus, label:active, input:checked + label {
  color: #47d0fa;
}
.active-label:before, label:focus:before, label:active:before, input:checked + label:before {
  background: white;
  border-color: #47d0fa;
}
/** * How it should look when checked */
input:checked + label:before {
  border-color: white;
  border: none;
  background: #47d0fa;
}
/** * How it should look when disabled */
input:disabled + label {
  background: #efefef;
  color: rgba(0, 0, 0, .5);
  cursor: not-allowed;
}

input:disabled + label:before {
  border-color: white;
  background: white;
}

/* input:before {
     content: "";
     position: relative;
     top: 18px;
     bottom: 110%;
     left: 25px;
     border-left: 2px solid #fff;
     margin: 0;
     overflow: visible;
 } */

@media (hover: hover) {
 label:hover:before {
     background: white;
     border-color: #47d0fa;
 }
 label:hover {
     color: #47d0fa;
 }
}

/* Slider */
input[type=range][orient=vertical]
{
 writing-mode: bt-lr; /* IE */
 -webkit-appearance: slider-vertical; /* WebKit */
 width: 8px;
 height: 175px;
 padding: 0 5px;
}

form div:before {
 content: "";
 position: relative;
 top: 18px;
 bottom: 110%;
 left: 25px;
 border-left: 2px solid #fff;
 margin: 0;
 overflow: visible;
}

form div:last-child:before, form div:nth-last-child(2):before {
border-left: none;
}

.border {
 border: 1px solid #ffffff96;
 border-radius: 1em;
 width: 100%;
 padding: 0 .5rem;
}

hr {
 width: 100%;
}

@keyframes transForth {
from {transform: translateX(100vw);}
to {transform:translateX(0);}
}

@keyframes transBack {
from {transform: translateX(-100vw);}
to {transform:translateX(0);}
}

.nextPage {
animation-name: transForth;
animation-duration: 500ms;
}

.prevPage {
animation-name: transBack;
animation-duration: 500ms;
}

#radio9 + label.rgb {
 background: #2e0657;
}
#radio8 + label.rgb {
 background: rgb(54, 7, 87);
}
#radio7 + label.rgb {
 background: rgb(72, 7, 87);
}
#radio6 + label.rgb {
 background: rgb(80, 7, 87);
}
#radio5 + label.rgb {
 background: rgb(88, 7, 87);
}
#radio4 + label.rgb {
 background: rgb(96, 7, 87);
}
#radio3 + label.rgb {
 background: rgb(104, 7, 87);
}
#radio2 + label.rgb {
 background: rgb(112, 7, 87);
}
#radio1 + label.rgb {
 background: rgb(120, 7, 87);
}
#radio0 + label.rgb {
 background: rgb(128, 7, 87);
}