.navigation {
    background: #47d0fa;
    font-family: "Catamaran","Arial Narrow",Arial,Helvetica,sans-serif;
    font-size: 18px;
}
.navigation__list {
    zoom: 1;
    background: #47d0fa;
    margin: 0;
    padding: 0;
    font-family: "Roboto","Arial Narrow",Arial,Helvetica,sans-serif;
    font-size: 18px;
    font-weight: 400;
    width: 130px;
    list-style: none;
    position: absolute;
    bottom: 100%;
}
#navigation .navigation__list a {
    text-decoration: none;
    color: #12094e;
    padding: 6px 10px;
    display: inline-block;
    width: 100%;
    text-align: left;
}
#navigation .navigation__list a:hover {
    background-color: #368dd5;
    color: #fff;
}
.navigation__list ul {
    list-style: none;
    z-index: 1000;
}
#navigation ul.navigation__drop li {
    width: 100%;
    background: #47d0fa;
}
input.navigation__checkbox {
    display: none;
}
.navigation__button {
    position: relative;
    margin: 0 auto;
    z-index: 2000;
    text-align: center;
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: #47d0fa;
    filter: drop-shadow( 1px 1px 2px #000 );
}
.navigation__icon {
    position: relative;
    margin-top: 21px;
}
.navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 2rem;
    height: 4px;
    background-color: #12094e;
    display: inline-block;
}
.navigation__icon::before, .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
}
.navigation__icon::before {
    top: -0.6rem;
}
.navigation__icon::after {
    top: 0.6rem;
}
.navigation__button:hover .navigation__icon::before {
    top: -0.6rem;
}
.navigation__button:hover .navigation__icon::after {
    top: 0.6rem;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg);
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg);
}
.navigation label {
    user-select: none;
}

.navigation__mobile {
    display: block;
}
#navigation .navigation__list li {
    width: 100%;
    padding: 0;
}

#navigation .navigation__list li:not(:last-child) {
    border-bottom: 1px solid #00000070;
}

#navigation .navigation__list li:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
}
#navigation .navigation__list li > div {
    padding: .5rem;
    color: #12094e;
}
.navigation__list li a {
    padding: 1rem 0;
}
.navigation__drop {
    position: relative;
    width: 100%;
    border: 2px solid #fff;
}
.navigation__checkbox ~ .navigation__list {
    display: none;
}
.navigation__checkbox:checked ~ .navigation__list {
    animation: scrollOpenAnimation 250ms;
    display: block;
}

@keyframes scrollOpenAnimation {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 100%;
  }
}