.tabs {
  width: 100%;
  float: none;
  list-style: none;
  position: relative;
  text-align: left;
  padding: 0;
  z-index: 0;
}
.tabs__div {
    width: 100%;
    align-self: center;
    user-select: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    height: calc(100vh - 100px);
    margin: 0px;
}

.tabs > li {
  float: left;
  display: block;
}
.tabs input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
}
.tabs .tab-content {
  z-index: 2;
  display: none;
  overflow-y: scroll;
  height: calc(100vh - 200px);
  width: 100%;
  padding: 1rem;
  position: absolute;
  top: 53px;
  left: 0;
  background: #612e76;
}
.tabs [id^="tab"]:checked + label {
  top: 0;
  padding-top: 17px;
  background: #612e76;
}
.tabs [id^="tab"]:checked ~ [id^="tab-content"] {
  display: block;
}

p.link {
  clear: both;
}
p.link a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  padding: 5px 10px;
  background-color: #612e76;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
p.link a:hover {
  background-color: #522764;
}
